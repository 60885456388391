import { useState, useRef, useEffect } from 'react'
import emailjs from 'emailjs-com'
import React from 'react'

const initialState = {
  name: '',
  email: '',
  phone: '',
  message: '',
}

export const Contact = (props) => {
  const [{ name, email, phone, message }, setState] = useState(initialState)
  const [emailStatus, setEmailStatus] = useState(null)
  const [formErrors, setFormErrors] = useState({ email: '', phone: '' })

  // Reference to the name input field
  const nameInputRef = useRef(null)

  useEffect(() => {
    // Function to focus on the name input field if the URL hash is '#contact'
    const handleHashChange = () => {
      if (window.location.hash === '#contact') {
        nameInputRef.current?.focus()
      }
    }

    // Run on initial load
    handleHashChange()

    // Add event listener to monitor hash changes
    window.addEventListener('hashchange', handleHashChange)

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('hashchange', handleHashChange)
    }
  }, [])

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))

    // Email validation
    if (name === 'email') {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/ // Basic email pattern
      if (!emailPattern.test(value)) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          email: 'ایمیل نامعتبر است',
        }))
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          email: '',
        }))
      }
    }

    // Phone number validation
    if (name === 'phone') {
      const phonePattern = /^09\d{9}$/ // Must be exactly 11 digits and start with 09
      if (!phonePattern.test(value)) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          phone: 'شماره موبایل باید با 09 شروع شود و 11 رقم باشد',
        }))
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          phone: '',
        }))
      }
    }
  }

  const clearState = () => setState({ ...initialState })

  const handleSubmit = (e) => {
    e.preventDefault()

    // Prevent submission if there are errors
    if (formErrors.email || formErrors.phone) {
      setEmailStatus('error')
      setTimeout(() => setEmailStatus(null), 8000)
      return
    }

    emailjs
      .sendForm(
        'service_yvb7aag',
        'template_yn40dok',
        e.target,
        'lLe5lSF4lwmqLjZPN'
      )
      .then(
        (result) => {
          console.log(result.text)
          setEmailStatus('success')
          clearState()

          // Automatically hide the alert after 8 seconds
          setTimeout(() => setEmailStatus(null), 8000)
        },
        (error) => {
          console.log(error.text)
          setEmailStatus('error')

          // Automatically hide the alert after 8 seconds
          setTimeout(() => setEmailStatus(null), 8000)
        }
      )
  }

  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title" dir="rtl">
                <h2>با ما در تماس باشید</h2>
                <p>
                  لطفا از طریق فرم زیر و با ذکر شماره موبایل خود در پیام، برای
                  ما ایمیل ارسال نمایید. در اسرع وقت با شما تماس خواهیم گرفت
                </p>
              </div>

              <form name="sentMessage" validate onSubmit={handleSubmit}>
                <div className="row" dir="rtl">
                  <div className="col-md-4">
                    <div className="form-group">
                      <input
                        dir="ltr"
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="ایمیل"
                        required
                        tabIndex="3"
                        onChange={handleChange}
                        value={email}
                      />
                      {formErrors.email && (
                        <p className="help-block text-danger">
                          {formErrors.email}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group">
                      <input
                        dir="ltr"
                        type="tel"
                        id="phone"
                        name="phone"
                        className="form-control"
                        placeholder="موبایل"
                        required
                        tabIndex="2"
                        onChange={handleChange}
                        value={phone}
                      />
                      {formErrors.phone && (
                        <p className="help-block text-danger">
                          {formErrors.phone}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group">
                      <input
                        dir="rtl"
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="نام"
                        required
                        tabIndex="1"
                        onChange={handleChange}
                        value={name}
                        ref={nameInputRef} // Attach the ref here
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>

                <div className="form-group" dir="rtl">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="متن پیام"
                    required
                    onChange={handleChange}
                    tabIndex="4"
                    value={message}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>

                <div id="success"></div>

                <button
                  type="submit"
                  className="btn btn-custom btn-lg"
                  tabIndex="5"
                >
                  ارسال پیام
                </button>
              </form>

              {/* Conditionally render success or error message */}
              {emailStatus === 'success' && (
                <div
                  className="alert alert-success"
                  role="alert"
                  style={{ marginTop: '10px' }}
                >
                  ✅ پیام شما با موفقیت ارسال شد.
                </div>
              )}
              {emailStatus === 'error' && (
                <div
                  className="alert alert-danger"
                  role="alert"
                  style={{ marginTop: '10px' }}
                >
                  ❌ پیام شما ارسال نشد، لطفا دوباره تلاش کنید.
                </div>
              )}
            </div>
          </div>

          <div className="col-md-3 col-md-offset-1 contact-info" dir="rtl">
            <div className="contact-item">
              <h3>اطلاعات تماس</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> آدرس
                </span>
                {props.data ? props.data.address : 'loading'}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> موبایل
                </span>{' '}
                <a href={`tel:${props.data ? props.data.mobile : '#'}`}>
                  <p className="contact-details">
                    {props.data ? props.data.mobile : 'loading'}
                  </p>
                </a>
              </p>
              <p>
                <span>
                  <i className="fa fa-phone"></i> تلفن
                </span>{' '}
                <a href={`tel:${props.data ? props.data.phone : '#'}`}>
                  <p className="contact-details">
                    {props.data ? props.data.phone : 'loading'}
                  </p>
                </a>
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> ایمیل
                </span>{' '}
                <a href={`mailto:${props.data ? props.data.email : '#'}`}>
                  <p className="contact-details">
                    {props.data ? props.data.email : 'loading'}
                  </p>
                </a>
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href={props.data ? props.data.telegram : '/'}>
                      <i className="fa fa-telegram"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.whatsapp : '/'}>
                      <i className="fa fa-whatsapp"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.instagram : '/'}>
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.twitter : '/'}>
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; 2023 Designed by Sun Coding Iran.{' '}
            <a href="https://www.SunCodingIran.ir" rel="nofollow">
              SunCodingIran
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}

import React, { useRef, useEffect } from 'react'

export const Navigation = (props) => {
  const navbarCollapseRef = useRef(null)

  useEffect(() => {
    // Close the navbar when a link is clicked
    const handleNavLinkClick = () => {
      if (navbarCollapseRef.current) {
        // Use Bootstrap's jQuery collapse function to hide the navbar
        window.$(navbarCollapseRef.current).collapse('hide')
      }
    }

    // Attach click event to all navbar links
    const links = document.querySelectorAll('.navbar-collapse a')
    links.forEach((link) => link.addEventListener('click', handleNavLinkClick))

    // Cleanup event listeners when the component is unmounted
    return () => {
      links.forEach((link) =>
        link.removeEventListener('click', handleNavLinkClick)
      )
    }
  }, [])

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container-fluid">
        <div className="row">
          {/* Left side */}
          <div className="col-md-8">
            <div
              className="collapse navbar-collapse"
              id="bs-example-navbar-collapse-1"
              ref={navbarCollapseRef}
            >
              <ul className="nav navbar-nav">
                <li>
                  <a href="#contact" className="page-scroll">
                    تـمـاس بـا مـا
                  </a>
                </li>
                <li>
                  <a href="#about" className="page-scroll">
                    دربـاره مـا
                  </a>
                </li>
                <li>
                  <a href="#team" className="page-scroll">
                    تـیــم مــا
                  </a>
                </li>

                <li>
                  <a href="#gallery" className="page-scroll">
                    گـالــری
                  </a>
                </li>
                {/* <li>
                  <a href="#testimonials" className="page-scroll">
                    نـظــرات
                  </a>
                </li> */}
                <li>
                  <a href="#features" className="page-scroll">
                    ویـژگـی هـا
                  </a>
                </li>
                <li>
                  <a href="#services" className="page-scroll">
                    خــدمــات
                  </a>
                </li>
              </ul>
            </div>
          </div>

          {/* Right side */}
          <div className="col-md-4">
            <div className="navbar-header navbar-right">
              <button
                type="button"
                className="navbar-toggle collapsed"
                data-toggle="collapse"
                data-target="#bs-example-navbar-collapse-1"
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>

              <a className="navbar-brand page-scroll" href="#page-top">
                ســـان کــدیــنــگ ایـــــران
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}

import React from 'react'

export const About = (props) => {
  return (
    <div id="about">
      <div className="container" dir="rtl">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {' '}
            <img src="img/about.jpg" className="img-responsive" alt="" />{' '}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>درباره ما</h2>
              <p>{props.data ? props.data.paragraph : 'loading...'}</p>
              <h3>دلیل انتخاب سان کدینگ ایران برای انجام پروژه ها:</h3>
              <div className="list-style">
                {/* <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : 'loading'}
                  </ul>
                </div> */}
                <div className="col-lg-12 col-sm-12 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why2.map((d, i) => (
                          <li key={`${d}-${i}`}> {d}</li>
                        ))
                      : 'loading'}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
